import { ApexCharsSeries } from '@/shared/dtos/DashboardsDtos/ApexCharsSeries';
import { DashTipoDatoFecha } from '@/shared/dtos/DashboardsDtos/DashTipoDatoFecha';
import { DashTipoDatoPorcen } from '@/shared/dtos/DashboardsDtos/DashTipoDatoPorcen';

export class UtilsDash {
    public static GetCategories(fechas: DashTipoDatoFecha[]): string[] {
        let strings: string[] = [];
        for (let i = 0; i < fechas.length; i++) {
            const element = new Date(fechas[i].fecha);
            strings.push((UtilsDash.FormatNumber(element.getMonth() + 1) + '/' + element.getFullYear().toString()));
        }
        return strings;
    }

    public static GetCategoriesTipo(datos: DashTipoDatoPorcen[]): string[] {
        return datos.map((x) => x.tipo);
    }

    public static GetSeries(source: DashTipoDatoFecha[]): ApexCharsSeries {
        let apex: ApexCharsSeries = new ApexCharsSeries();
        apex.data = [];
        for (let i = 0; i < source.length; i++) {
            apex.name = source[i].tipo;
            apex.data.push(source[i].total);
        }
        return apex;
    }
    public static FormatNumber(num: number): string {
        if (num <= 9) {
            return "0" + num.toString();
        }
        return num.toString()
    }
}